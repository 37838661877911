import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from "react"
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faUserPlus, faCartShopping, faCartPlus, faUnlock, faHouseChimney, faMagnifyingGlass, faArrowDownWideShort, faFilterCircleDollar } from '@fortawesome/free-solid-svg-icons'
library.add(faUser, faUserPlus, faCartShopping, faCartPlus, faUnlock, faHouseChimney, faMagnifyingGlass, faArrowDownWideShort, faFilterCircleDollar)

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }


  render() {
    return (
      window.location.replace("https://www.etsy.com/es/shop/KILOxCO")
    )
  }
}

export default App
